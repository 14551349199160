.main--projects {
  height: 100vh;
}

.section-header--projects {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  padding-top: 35px;
  margin-bottom: 10px;
}

.isometric-terrain-generation-img--projects {
  width: 50%;
  height: 50%;
}

.social-icon {
  margin: 3px;
  font-size: 30px;
}

ul li {
  padding: 10px;
}

