.cert-image-dimensions {
  width: 50%;
  min-width: 100px;
  padding: 1px;
}

.certs-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 30px;
  max-width: 300px;
  flex: 1 0 40%; /* Adjust the percentage to control the number of items per row */
}

.certs-bar {
  flex: "1";
  border-bottom: 2px dashed transparent;
  width: 100%;
  border-color: #333;
}

.certs {
  padding-top: 15px;
}

