.mission--personal--container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px, 10%, 10%, 15px;
}

.main {
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.box {
  display: flex;
  flex-direction: row wrap;
  justify-content: center;
  align-items: center;
  width: 35%;
  text-align: left;
  cursor: pointer;
  margin: auto;
  margin-top: 1em;
  text-decoration: none;
}
.theme-light .box {
  background: whitesmoke;
  color: black;
}
.theme-dark .box {
  background: black;
  color: white;
}
.theme-retro .box {
  background: #1c1636;
  color: #fc74c6;
}

.box--first {
  margin-top: 2em;
}

.theme-light .box:hover {
  background: yellow;
}
.theme-dark .box:hover {
  background: blue;
}
.theme-retro .box:hover {
  background: #7a04eb;
}

.text {
  border: 0;
  text-transform: uppercase;
  font-weight: 250;
  font-style: bold;
  font-size: 2.75vw;
  margin: auto;
  margin-left: 5px;
  line-height: 0.8;
}
.theme-light .text {
  color: black;
}
.theme-dark .text {
  color: white;
}
.theme-retro .text {
  color: #fc74c6;
}

.text--name {
  padding-left: 5px;
  font-weight: 250;
  font-style: bold;
  font-size: 2vw;
  overflow: hidden;
  margin: 0 auto;
  letter-spacing: 1em;
  animation: typing 2s steps(30, end), blinking-caret 1s step-end infinite;
}
.theme-light .text--name {
  color: black;
  border-right: 0.15em solid black;
}
.theme-dark .text--name {
  color: white;
  border-right: 0.15em solid white;
}
.theme-retro .text--name {
  color: #fc74c6;
  border-right: 0.15em solid #fc74c6;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blinking-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: inherit;
  }
}
@media only screen and (max-width: 991px) {
  .mission--personal--container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .mission--personal--container {
    flex-direction: column;
  }
}

