@import "../../../../scss/functions";
@import "../../../../scss/mixins";

.icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.icon:before,
.icon:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s
}

.icon:before {
  background: #fff;
  border: 2px solid #232323;
  left: -3px
}

.icon:after {
  border: 2px solid #c6c6c6;
  left: 3px
}

.date-content {
  width: 50%;
  float: left;
  margin-top: 22px;
  position: relative
}

.date-content:before {
  content: "";
  width: 36.5%;
  height: 2px;
  background: #c6c6c6;
  margin: auto 0;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0
}

.date-outer {
  width: 125px;
  height: 125px;
  font-size: 16px;
  text-align: center;
  margin: auto;
  z-index: 1
}

.date-outer:before,
.date-outer:after {
  content: "";
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s
}

.date-outer:before {
  background: #fff;
  border: 2px solid #232323;
  left: -6px
}

.date-outer:after {
  border: 2px solid #c6c6c6;
  left: 6px
}

.timeline:hover .date-outer:before {
  left: 6px
}

.timeline:hover .date-outer:after {
  left: -6px
}

.certs--skills--container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 27%;
  left: 0
}

.work-duration {
  font-size: 12px;
  font-weight: 700;

  @include themify() {
    color: theme("work-duration-color");
  }
}

.year {
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #232323;
  line-height: 36px
}

.job-content {
  width: 50%;
  padding: 45px 0 20px 50px;
  float: right
}

.title {
  text-align: left;  
  line-height: 24px
}   

.title-company {
  font-size: 25px;
  font-weight: 700;
  text-decoration: underline;
}

.title-jobrole {
  font-size: 20px;
  font-weight: 500;
  padding-top: 5px;
}

.description {
  margin-bottom: 0;
  text-align: left;
}

.timeline:nth-child(2n) .date-content {
  float: right
}

.timeline:nth-child(2n) .date-content:before {
  left: 10px
}

.timeline:nth-child(2n) .job-content {
  padding: 45px 50px 20px 0;
  text-align: right
}

.extra-details {
  padding-left: 5px;
  max-width: 500px;
}
@media only screen and (max-width: 991px) {
  .date-content {
      margin-top: 35px
  }
  .date-content:before {
      width: 22.5%
  }
  .job-content {
      padding: 45px 0 10px 30px
  }
  .title {
      font-size: 17px
  }
  .timeline:nth-child(2n) .job-content {
      padding: 45px 30px 10px 0
  }
  .description .job-details  {
    font-size: 14px
  }

  .certs--skills--container {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .timeline {
      margin-bottom: 20px
  }
  .timeline:last-child {
      margin-bottom: 0
  }
  .icon {
      margin: auto 0;
      left: -10px
  }
  .date-content {
      width: 95%;
      float: right;
      margin-top: 0
  }
  .date-content:before {
      display: none
  }
  .date-outer {
      width: 110px;
      height: 110px
  }
  .date-outer:before,
  .date-outer:after {
      width: 110px;
      height: 110px
  }
  .date {
      top: 30%
  }

  .year {
      font-size: 24px
  }
  .job-content,
  .timeline:nth-child(2n) .job-content {
      width: 95%;
      text-align: center;
      padding: 10px 0
  }
  .title {
      margin-bottom: 10px
  }

  .work-duration {
    font-size: 14px;
  }
  
}