.nav--icon {
  margin: 5px;
  height: 2vh;
  vertical-align: middle;
}

.theme-light .navbar-toggler {
  background-color: whitesmoke !important;
  border-color: black !important;
}
.theme-dark .navbar-toggler {
  background-color: white !important;
  border-color: white !important;
}
.theme-retro .navbar-toggler {
  background-color: #fc74c6 !important;
  border-color: #fc74c6 !important;
}

.theme-light .navbar-brand {
  color: black !important;
}
.theme-dark .navbar-brand {
  color: white !important;
}
.theme-retro .navbar-brand {
  color: #fe75fe !important;
}

.theme-light .nav-link {
  color: black !important;
}
.theme-dark .nav-link {
  color: white !important;
}
.theme-retro .nav-link {
  color: #fe75fe !important;
}

.nav-link:hover {
  text-decoration: underline;
}

.theme-light .dropdown-item:hover {
  color: whitesmoke !important;
  background-color: black !important;
}
.theme-dark .dropdown-item:hover {
  color: black !important;
  background-color: white !important;
}
.theme-retro .dropdown-item:hover {
  color: #1c1636 !important;
  background-color: #fe75fe !important;
}

.theme-light .dropdown-menu {
  background-color: whitesmoke;
}
.theme-dark .dropdown-menu {
  background-color: black;
}
.theme-retro .dropdown-menu {
  background-color: #1c1636;
}

.nav-item.show.dropdown > div {
  /* Not overriding correctly */
  max-width: 80px !important;
}

.nav-item.show.dropdown > div > a {
  border-bottom: 2px solid;
}

.nav-item.show.dropdown > div > a :last-child {
  border-bottom: none;
}

