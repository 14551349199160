.career-role {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  border-radius: 20px;
}
.theme-light .career-role {
  background-color: gold;
}
.theme-dark .career-role {
  background-color: blue;
}
.theme-retro .career-role {
  background-color: #7a04eb;
}

