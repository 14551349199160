@charset "UTF-8";
.mission--statement {
  font-weight: 700;
  font-size: 30px;
  padding: 2rem !important;
  max-width: 600px;
}

.mission--statement--aboutme {
  padding: 0 0 0 3px;
}
.mission--statement--aboutme::before {
  content: "​";
  content: "​"/"Architect";
  alt: "Architect";
  white-space: break-spaces;
  will-change: content;
  animation: typed-0 21.2s linear 1s infinite forwards;
}
.mission--statement--aboutme::after {
  content: "​";
  position: relative;
  display: inline-block;
  padding-right: 1px;
  border-right: 2px solid orange;
  white-space: nowrap;
  animation: typed-0-caret 0.65s linear 1s infinite forwards;
}
@keyframes typed-0 {
  0%, 0.7065471698% {
    content: "​A";
    content: "​A"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  0.7075471698%, 1.4140943396% {
    content: "​Ar";
    content: "​Ar"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  1.4150943396%, 2.1216415094% {
    content: "​Arc";
    content: "​Arc"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  2.1226415094%, 2.8291886792% {
    content: "​Arch";
    content: "​Arch"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  2.8301886792%, 3.5367358491% {
    content: "​Archi";
    content: "​Archi"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  3.5377358491%, 4.2442830189% {
    content: "​Archit";
    content: "​Archit"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  4.2452830189%, 4.9518301887% {
    content: "​Archite";
    content: "​Archite"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  4.9528301887%, 5.6593773585% {
    content: "​Architec";
    content: "​Architec"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  5.6603773585%, 16.178245283% {
    content: "​Architect";
    content: "​Architect"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  16.179245283%, 16.5556037736% {
    content: "​Architec";
    content: "​Architec"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  16.5566037736%, 16.9329622642% {
    content: "​Archite";
    content: "​Archite"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  16.9339622642%, 17.3103207547% {
    content: "​Archit";
    content: "​Archit"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  17.3113207547%, 17.6876792453% {
    content: "​Archi";
    content: "​Archi"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  17.6886792453%, 18.0650377358% {
    content: "​Arch";
    content: "​Arch"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  18.0660377358%, 18.4423962264% {
    content: "​Arc";
    content: "​Arc"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  18.4433962264%, 18.819754717% {
    content: "​Ar";
    content: "​Ar"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  18.820754717%, 19.1971132075% {
    content: "​A";
    content: "​A"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  19.1981132075%, 23.9140943396% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  23.9150943396%, 24.6216415094% {
    content: "​C";
    content: "​C"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  24.6226415094%, 25.3291886792% {
    content: "​Co";
    content: "​Co"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  25.3301886792%, 26.0367358491% {
    content: "​Col";
    content: "​Col"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  26.0377358491%, 26.7442830189% {
    content: "​Coll";
    content: "​Coll"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  26.7452830189%, 27.4518301887% {
    content: "​Colla";
    content: "​Colla"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  27.4528301887%, 28.1593773585% {
    content: "​Collab";
    content: "​Collab"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  28.1603773585%, 28.8669245283% {
    content: "​Collabo";
    content: "​Collabo"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  28.8679245283%, 29.5744716981% {
    content: "​Collabor";
    content: "​Collabor"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  29.5754716981%, 30.2820188679% {
    content: "​Collabora";
    content: "​Collabora"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  30.2830188679%, 30.9895660377% {
    content: "​Collaborat";
    content: "​Collaborat"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  30.9905660377%, 31.6971132075% {
    content: "​Collaborate";
    content: "​Collaborate"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  31.6981132075%, 32.4046603774% {
    content: "​Collaborate ";
    content: "​Collaborate "/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  32.4056603774%, 33.1122075472% {
    content: "​Collaborate o";
    content: "​Collaborate o"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  33.1132075472%, 43.6310754717% {
    content: "​Collaborate on";
    content: "​Collaborate on"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  43.6320754717%, 44.0084339623% {
    content: "​Collaborate o";
    content: "​Collaborate o"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  44.0094339623%, 44.3857924528% {
    content: "​Collaborate ";
    content: "​Collaborate "/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  44.3867924528%, 44.7631509434% {
    content: "​Collaborate";
    content: "​Collaborate"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  44.7641509434%, 45.140509434% {
    content: "​Collaborat";
    content: "​Collaborat"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  45.141509434%, 45.5178679245% {
    content: "​Collabora";
    content: "​Collabora"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  45.5188679245%, 45.8952264151% {
    content: "​Collabor";
    content: "​Collabor"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  45.8962264151%, 46.2725849057% {
    content: "​Collabo";
    content: "​Collabo"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  46.2735849057%, 46.6499433962% {
    content: "​Collab";
    content: "​Collab"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  46.6509433962%, 47.0273018868% {
    content: "​Colla";
    content: "​Colla"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  47.0283018868%, 47.4046603774% {
    content: "​Coll";
    content: "​Coll"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  47.4056603774%, 47.7820188679% {
    content: "​Col";
    content: "​Col"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  47.7830188679%, 48.1593773585% {
    content: "​Co";
    content: "​Co"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  48.1603773585%, 48.5367358491% {
    content: "​C";
    content: "​C"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  48.5377358491%, 53.2537169811% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  53.2547169811%, 53.9612641509% {
    content: "​E";
    content: "​E"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  53.9622641509%, 54.6688113208% {
    content: "​En";
    content: "​En"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  54.6698113208%, 55.3763584906% {
    content: "​Eng";
    content: "​Eng"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  55.3773584906%, 56.0839056604% {
    content: "​Engi";
    content: "​Engi"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  56.0849056604%, 56.7914528302% {
    content: "​Engin";
    content: "​Engin"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  56.7924528302%, 57.499% {
    content: "​Engine";
    content: "​Engine"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  57.5%, 58.2065471698% {
    content: "​Enginee";
    content: "​Enginee"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  58.2075471698%, 68.7254150943% {
    content: "​Engineer";
    content: "​Engineer"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  68.7264150943%, 69.1027735849% {
    content: "​Enginee";
    content: "​Enginee"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  69.1037735849%, 69.4801320755% {
    content: "​Engine";
    content: "​Engine"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  69.4811320755%, 69.857490566% {
    content: "​Engin";
    content: "​Engin"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  69.858490566%, 70.2348490566% {
    content: "​Engi";
    content: "​Engi"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  70.2358490566%, 70.6122075472% {
    content: "​Eng";
    content: "​Eng"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  70.6132075472%, 70.9895660377% {
    content: "​En";
    content: "​En"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  70.9905660377%, 71.3669245283% {
    content: "​E";
    content: "​E"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  71.3679245283%, 76.0839056604% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  76.0849056604%, 76.7914528302% {
    content: "​I";
    content: "​I"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  76.7924528302%, 77.499% {
    content: "​Im";
    content: "​Im"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  77.5%, 78.2065471698% {
    content: "​Imp";
    content: "​Imp"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  78.2075471698%, 78.9140943396% {
    content: "​Impl";
    content: "​Impl"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  78.9150943396%, 79.6216415094% {
    content: "​Imple";
    content: "​Imple"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  79.6226415094%, 80.3291886792% {
    content: "​Implem";
    content: "​Implem"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  80.3301886792%, 81.0367358491% {
    content: "​Impleme";
    content: "​Impleme"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  81.0377358491%, 81.7442830189% {
    content: "​Implemen";
    content: "​Implemen"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  81.7452830189%, 92.2631509434% {
    content: "​Implement";
    content: "​Implement"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  92.2641509434%, 92.640509434% {
    content: "​Implemen";
    content: "​Implemen"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  92.641509434%, 93.0178679245% {
    content: "​Impleme";
    content: "​Impleme"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  93.0188679245%, 93.3952264151% {
    content: "​Implem";
    content: "​Implem"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  93.3962264151%, 93.7725849057% {
    content: "​Imple";
    content: "​Imple"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  93.7735849057%, 94.1499433962% {
    content: "​Impl";
    content: "​Impl"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  94.1509433962%, 94.5273018868% {
    content: "​Imp";
    content: "​Imp"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  94.5283018868%, 94.9046603774% {
    content: "​Im";
    content: "​Im"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  94.9056603774%, 95.2820188679% {
    content: "​I";
    content: "​I"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  95.2830188679%, 100% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
}
@keyframes typed-0-caret {
  75% {
    border-color: transparent;
  }
}

.mission--statement--aboutme--v2 {
  padding: 0 0 0 3px;
}
.mission--statement--aboutme--v2::before {
  content: "​";
  content: "​"/"Architect";
  alt: "Architect";
  white-space: break-spaces;
  will-change: content;
  animation: typed-1 20.74s linear 1s infinite forwards;
}
.mission--statement--aboutme--v2::after {
  content: "​";
  position: relative;
  display: inline-block;
  padding-right: 1px;
  border-right: 2px solid orange;
  white-space: nowrap;
  animation: typed-1-caret 0.65s linear 1s infinite forwards;
}
@keyframes typed-1 {
  0%, 0.7222401157% {
    content: "​A";
    content: "​A"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  0.7232401157%, 1.4454802314% {
    content: "​Ar";
    content: "​Ar"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  1.4464802314%, 2.1687203472% {
    content: "​Arc";
    content: "​Arc"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  2.1697203472%, 2.8919604629% {
    content: "​Arch";
    content: "​Arch"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  2.8929604629%, 3.6152005786% {
    content: "​Archi";
    content: "​Archi"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  3.6162005786%, 4.3384406943% {
    content: "​Archit";
    content: "​Archit"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  4.3394406943%, 5.06168081% {
    content: "​Archite";
    content: "​Archite"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  5.06268081%, 5.7849209257% {
    content: "​Architec";
    content: "​Architec"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  5.7859209257%, 16.5370906461% {
    content: "​Architect";
    content: "​Architect"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  16.5380906461%, 16.9228187078% {
    content: "​Architec";
    content: "​Architec"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  16.9238187078%, 17.3085467695% {
    content: "​Archite";
    content: "​Archite"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  17.3095467695%, 17.6942748312% {
    content: "​Archit";
    content: "​Archit"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  17.6952748312%, 18.080002893% {
    content: "​Archi";
    content: "​Archi"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  18.081002893%, 18.4657309547% {
    content: "​Arch";
    content: "​Arch"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  18.4667309547%, 18.8514590164% {
    content: "​Arc";
    content: "​Arc"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  18.8524590164%, 19.2371870781% {
    content: "​Ar";
    content: "​Ar"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  19.2381870781%, 19.6229151398% {
    content: "​A";
    content: "​A"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  19.6239151398%, 24.4445159113% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: red;
    background-color: red;
    background-color: red;
    background-color: red;
  }
  24.4455159113%, 25.167756027% {
    content: "​C";
    content: "​C"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  25.168756027%, 25.8909961427% {
    content: "​Co";
    content: "​Co"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  25.8919961427%, 26.6142362584% {
    content: "​Col";
    content: "​Col"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  26.6152362584%, 27.3374763742% {
    content: "​Coll";
    content: "​Coll"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  27.3384763742%, 28.0607164899% {
    content: "​Colla";
    content: "​Colla"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  28.0617164899%, 28.7839566056% {
    content: "​Collab";
    content: "​Collab"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  28.7849566056%, 29.5071967213% {
    content: "​Collabo";
    content: "​Collabo"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  29.5081967213%, 30.230436837% {
    content: "​Collabor";
    content: "​Collabor"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  30.231436837%, 30.9536769527% {
    content: "​Collabora";
    content: "​Collabora"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  30.9546769527%, 31.6769170685% {
    content: "​Collaborat";
    content: "​Collaborat"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  31.6779170685%, 32.4001571842% {
    content: "​Collaborato";
    content: "​Collaborato"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  32.4011571842%, 43.1523269045% {
    content: "​Collaborator";
    content: "​Collaborator"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  43.1533269045%, 43.5380549662% {
    content: "​Collaborato";
    content: "​Collaborato"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  43.5390549662%, 43.923783028% {
    content: "​Collaborat";
    content: "​Collaborat"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  43.924783028%, 44.3095110897% {
    content: "​Collabora";
    content: "​Collabora"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  44.3105110897%, 44.6952391514% {
    content: "​Collabor";
    content: "​Collabor"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  44.6962391514%, 45.0809672131% {
    content: "​Collabo";
    content: "​Collabo"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  45.0819672131%, 45.4666952748% {
    content: "​Collab";
    content: "​Collab"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  45.4676952748%, 45.8524233365% {
    content: "​Colla";
    content: "​Colla"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  45.8534233365%, 46.2381513983% {
    content: "​Coll";
    content: "​Coll"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  46.2391513983%, 46.62387946% {
    content: "​Col";
    content: "​Col"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  46.62487946%, 47.0096075217% {
    content: "​Co";
    content: "​Co"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  47.0106075217%, 47.3953355834% {
    content: "​C";
    content: "​C"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  47.3963355834%, 52.2169363549% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: green;
    background-color: green;
    background-color: green;
    background-color: green;
  }
  52.2179363549%, 52.9401764706% {
    content: "​E";
    content: "​E"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  52.9411764706%, 53.6634165863% {
    content: "​En";
    content: "​En"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  53.6644165863%, 54.386656702% {
    content: "​Eng";
    content: "​Eng"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  54.387656702%, 55.1098968177% {
    content: "​Engi";
    content: "​Engi"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  55.1108968177%, 55.8331369335% {
    content: "​Engin";
    content: "​Engin"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  55.8341369335%, 56.5563770492% {
    content: "​Engine";
    content: "​Engine"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  56.5573770492%, 57.2796171649% {
    content: "​Enginee";
    content: "​Enginee"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  57.2806171649%, 68.0317868852% {
    content: "​Engineer";
    content: "​Engineer"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  68.0327868852%, 68.417514947% {
    content: "​Enginee";
    content: "​Enginee"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  68.418514947%, 68.8032430087% {
    content: "​Engine";
    content: "​Engine"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  68.8042430087%, 69.1889710704% {
    content: "​Engin";
    content: "​Engin"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  69.1899710704%, 69.5746991321% {
    content: "​Engi";
    content: "​Engi"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  69.5756991321%, 69.9604271938% {
    content: "​Eng";
    content: "​Eng"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  69.9614271938%, 70.3461552555% {
    content: "​En";
    content: "​En"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  70.3471552555%, 70.7318833173% {
    content: "​E";
    content: "​E"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  70.7328833173%, 75.5534840887% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: blue;
    background-color: blue;
    background-color: blue;
    background-color: blue;
  }
  75.5544840887%, 76.2767242044% {
    content: "​T";
    content: "​T"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  76.2777242044%, 76.9999643202% {
    content: "​Te";
    content: "​Te"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  77.0009643202%, 77.7232044359% {
    content: "​Tec";
    content: "​Tec"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  77.7242044359%, 78.4464445516% {
    content: "​Tech";
    content: "​Tech"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  78.4474445516%, 79.1696846673% {
    content: "​Tech ";
    content: "​Tech "/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  79.1706846673%, 79.892924783% {
    content: "​Tech L";
    content: "​Tech L"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  79.893924783%, 80.6161648987% {
    content: "​Tech Le";
    content: "​Tech Le"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  80.6171648987%, 81.3394050145% {
    content: "​Tech Lea";
    content: "​Tech Lea"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  81.3404050145%, 92.0915747348% {
    content: "​Tech Lead";
    content: "​Tech Lead"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  92.0925747348%, 92.4773027965% {
    content: "​Tech Lea";
    content: "​Tech Lea"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  92.4783027965%, 92.8630308582% {
    content: "​Tech Le";
    content: "​Tech Le"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  92.8640308582%, 93.24875892% {
    content: "​Tech L";
    content: "​Tech L"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  93.24975892%, 93.6344869817% {
    content: "​Tech ";
    content: "​Tech "/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  93.6354869817%, 94.0202150434% {
    content: "​Tech";
    content: "​Tech"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  94.0212150434%, 94.4059431051% {
    content: "​Tec";
    content: "​Tec"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  94.4069431051%, 94.7916711668% {
    content: "​Te";
    content: "​Te"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  94.7926711668%, 95.1773992285% {
    content: "​T";
    content: "​T"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
  95.1783992285%, 100% {
    content: "​";
    content: "​"/"Architect";
    alt: "Architect";
    background-color: orange;
    background-color: orange;
    background-color: orange;
    background-color: orange;
  }
}
@keyframes typed-1-caret {
  75% {
    border-color: transparent;
  }
}

@media only screen and (max-width: 991px) {
  .mission--statement {
    font-size: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .mission--statement {
    font-weight: 700;
    font-size: 20px;
  }
}

