@import "../../../scss/functions";
@import "../../../scss/mixins";


.mission--personal--container {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 15px, 10%, 10%, 15px;
}

.main {
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.box {
  display: flex;
  flex-direction: row wrap;
  justify-content: center;
  align-items: center;
  width: 35%;
  @include themify() {
    background: theme("background-color");
    color: theme("text-color");
    //box-shadow: 0px 8px 16px 0px theme("text-color");
  }
  text-align: left;
  cursor: pointer;

  margin: auto;
  margin-top: 1em;
  text-decoration: none;
}
.box--first {
  margin-top: 2em;
}

.box:hover {
  @include themify() {
    background: theme("link-hover-color");

    //box-shadow: 0px 8px 16px 0px theme("text-color");
  }
}

.text {
  border: 0;
  text-transform: uppercase;
  font-weight: 250;
  font-style: bold;
  font-size: 2.75vw;
  margin: auto;
  margin-left: 5px;
  @include themify() {
    color: theme("text-color");
  }
  line-height: 0.8;
}

.text--name {
  padding-left: 5px;

  font-weight: 250;
  font-style: bold;
  font-size: 2vw;

  overflow: hidden;
  @include themify() {
    color: theme("text-color");
    border-right: 0.15em solid theme("text-color");
  }
  margin: 0 auto; //scrolling effect

  letter-spacing: 1em;
  animation: typing 2s steps(30, end), blinking-caret 1s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinking-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: inherit;
  }
}

@media only screen and (max-width: 991px) {
  .mission--personal--container {
    flex-direction: column;
   }
}

@media only screen and (max-width: 767px) {
  .mission--personal--container {
    flex-direction: column;
   }
}