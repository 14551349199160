body {
  font-family: sans-serif;
}

.main-theme {
  width: 100%;
  height: 100%;
}
.theme-light .main-theme {
  background: whitesmoke;
  color: black;
}
.theme-dark .main-theme {
  background: black;
  color: white;
}
.theme-retro .main-theme {
  background: #1c1636;
  color: #fc74c6;
}

.theme-light a {
  color: black;
}
.theme-dark a {
  color: white;
}
.theme-retro a {
  color: #7a04eb;
}

.main-theme .setters {
  display: flow-root;
}

.main-theme .setters ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: right;
}

.main-theme .setters li {
  float: left;
}

