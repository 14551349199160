.social-icon {
  margin: 3px;
  font-size: 40px;
}

.selfie {
  width: 325px;
  height: 325px;
  border-radius: 225px;
}

.name {
  font-size: 40px;
}

.whoAmI {
  max-width: 325px;
}
