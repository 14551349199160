@import "../../../../scss/functions";
@import "../../../../scss/mixins";

.career-role {
    @include themify() {
        background-color: theme("job-role-color")
    }
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    border-radius: 20px;
}