.main--hub {
  display: grid;
  align-items: center;
  height: 100vh;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.text--title {
  font-weight: 250;
  font-style: bold;
  font-size: 2.3rem;
  text-decoration: underline;
}

.box--hub {
  width: auto;
}

