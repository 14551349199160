@import "../../../scss/functions";
@import "../../../scss/mixins";

body {
  //font-family: 'Ubuntu Mono', monospace;
  font-family: sans-serif;
}

.main-theme {
  width: 100%;
  height: 100%;

  @include themify() {
    background: theme("background-color");
    color: theme("text-color");
  }
}

a {
  @include themify() {
    color: theme("link-color");
  }
}

.main-theme .setters {
  display: flow-root;
}

.main-theme .setters ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: right;
}

.main-theme .setters li {
  float: left;
}
