.school-name {
  text-align: left;
  font-size: 25px;
  font-weight: 700;
}

.school-degree {
  font-size: 15px;
  font-weight: 100;
}

.school-content {
  width: 50%;
  padding: 20px 0 20px 50px;
  float: right;
  margin-top: 50px;
}

.school-content:nth-child(2n) {
  padding: 0 20px 20px 50px;
}

.school-date {
  width: 100%;
  margin: auto;
  position: absolute;
  top: 35%;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .school-content {
    float: left;
    width: 100%;
  }
}

