@import "../../../scss/functions";
@import "../../../scss/mixins";

@import "typed";

.mission--statement {
  font-weight: 700;
  font-size: 30px;
  padding: 2rem !important;
  max-width: 600px;
}

.mission--statement--aboutme {
  padding: 0 0 0 3px;
  @include typed(
    (
      "Architect": (
        background-color: red,
      ),
      "Collaborate on": (
        background-color: green,
      ),
      "Engineer": (
        background-color: blue,
      ),
      "Implement": (
        background-color: orange,
      )),
      (type: 0.15),
      (
        iterations: infinite,
        caret-speed: 0.65,
        caret-width: 2px,
        caret-color: orange,
        caret-space: 1px
      ),
  );
}

.mission--statement--aboutme--v2 {
  padding: 0 0 0 3px;
  @include typed(
    (
      "Architect": (
        background-color: red,
      ),
      "Collaborator": (
        background-color: green,
      ),
      "Engineer": (
        background-color: blue,
      ),
      "Tech Lead": (
        background-color: orange,
      )),
      (type: 0.15),
      (
        iterations: infinite,
        caret-speed: 0.65,
        caret-width: 2px,
        caret-color: orange,
        caret-space: 1px
      ),
  );
}

@media only screen and (max-width: 991px) {
  .mission--statement {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .mission--statement {
    font-weight: 700;
    font-size: 20px;
  }
}