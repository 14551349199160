/* timeline css */
body {
  background-color: #f7f7f7;
}

.main-timeline {
  position: relative;
}

.main-timeline:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #c6c6c6;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main-timeline .timeline {
  margin-bottom: 40px;
  position: relative;
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both;
}

.timeline:hover .icon:before {
  left: 3px;
}

.timeline:hover .icon:after {
  left: -3px;
}

@media only screen and (max-width: 767px) {
  .main-timeline {
    left: 5px;
  }
  .main-timeline:before {
    margin: 0;
    left: 7px;
  }
  .main-timeline .timeline {
    margin-bottom: 20px;
  }
  .main-timeline .timeline:last-child {
    margin-bottom: 0;
  }
}

