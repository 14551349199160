@import "../../../scss/functions";
@import "../../../scss/mixins";

.nav--icon {
  margin: 5px;
  height: 2vh;
  vertical-align: middle;
}

.navbar-toggler {
  @include themify() {
    background-color: theme("nav-dropdown-color") !important;
    border-color: theme("text-color") !important;
  }
}
.navbar-brand {
  @include themify() {
    color: theme("nav-link-color") !important;
  }
}

.nav-link {
  @include themify() {
    color: theme("nav-link-color") !important;
  }
}

.nav-link:hover {
  text-decoration: underline;
}

.dropdown-item:hover {
  @include themify() {
    color: theme("background-color") !important;
    background-color: theme("nav-link-color") !important;
  }
}

.dropdown-menu {
  @include themify() {
    background-color: theme("background-color");
  }
}

.nav-item.show.dropdown > div {
  /* Not overriding correctly */
  max-width: 80px !important;
}

.nav-item.show.dropdown > div > a {
  border-bottom: 2px solid;
}

.nav-item.show.dropdown > div > a :last-child {
  border-bottom: none;
}
