.skills {
  padding-top: 15px;
  text-align: left;
}

.skill-center-horizontally {
  margin: 0 auto;
}

.skill--padding {
  padding-left: 10%;
}

.proficiency-bar {
  background-color: cadetblue;
  color: whitesmoke;
}

.proficiency-bar:hover {
  background-color: green;
}

.proficiency-bar-nil {
  background-color: lightgrey;
}

.skills-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 15px 0 5% 5%;
  max-width: 300px;
  flex: 1 0 40%; /* Adjust the percentage to control the number of items per row */
}

.skills-bar {
  flex: '1';
  border-bottom: 2px dashed transparent;
  width: 100%;
  border-color: #333;
}

.badge {
  margin-right: 5px;
}

@media only screen and (max-width: 991px) {
  .skills  {
    font-size: 14px
  }

  .skills-container {
    padding: 0 0 5% 5%;
  }
}